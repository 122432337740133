<template>
  <div class="absolute top-1/2 left-0 right-0 transform -translate-y-1/2 flex justify-between z-20">
    <button
        class="w-9 xl:w-12.5 h-9 xl:h-12.5 flex justify-center items-center rounded-full duration-300"
        :class="[prevEl, isClass(), option ? 'translate-x-10' : 'xl:-translate-x-20']"
    >
      <span class="w-5 xl:w-7.5 h-5 xl:h-7.5 i-custom-chevron-right transform rotate-180"> </span>
    </button>
    <button
        class="w-9 xl:w-12.5 h-9 xl:h-12.5 flex justify-center items-center rounded-full duration-300"
        :class="[nextEl, isClass(), option ? '-translate-x-10' : 'xl:translate-x-20']"
    >
      <span class="w-5 xl:w-7.5 h-5 xl:h-7.5 i-custom-chevron-right"> </span>
    </button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  nextEl: {
    type: String
  },
  prevEl: {
    type: String
  },
  option: {
    type: Boolean
  }
})

const isClass = () => {
  if (props.option) {
    return 'text-white/80 bg-primary hover:bg-transparent border hover:border-primary hover:text-primary'
  } else {
    return 'xl:text-primary xl:bg-final-03 hover:xl:bg-primary hover:xl:text-white border xl:border-none text-primary bg-final-03 '
  }
}
</script>

<style></style>
